<style>
.btn-gohome {
  width: 60%;
  margin: auto;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  color: #fff;
  background-color: #ff5011;
}
body.default-style #app-content #illness-review .blue-bg {
  background-image: linear-gradient(0deg, #005187 0%, #00457b 100%);
  color: #fff;
  text-align: center;
  padding: 0;
}
</style>
<template>
  <!-- <div id="illness-review" style="min-height: 90vh;display">
    <div class="blue-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-12 col-md-8 text-center pt-3"
            style="max-width: 600px"
          >
            <img src="images/thankyou-va.png" alt="" style="height: 200px" />

            <div class="mt-4">
              <h4 class="text-center">{{ $t("thankyou") }}</h4>
              <p class="my-4">{{ $t("message") }}</p>
              <p class="mb-4">
                {{ $t("message2") }}
                <a
                  href="https://myaccount.roojai.co.id/"
                  target="_blank"
                  role="button"
                  style="color: #ff5011"
                  data-selenium-name="footer-roojai-number"
                  data-selenium-value="021 5089 0822"
                >
                  MyAccount
                </a>
                {{ $t("message3") }}
              </p>
              <p class="mb-4">{{ $t("message4") }}</p>
              <p>{{ $t("no_contact") }}</p>
            </div> -->

            <!-- <div class="text-center" style="margin: 3vh auto 0;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div> -->
          <!-- </div>
        </div>
      </div>
    </div>
    <div class="my-4" style="max-width: 40em; margin: auto">
      <button
        class="btn btn-gohome btn-block"
        data-selenium-name="floating-buynow"
        @click="gobackhome()"
      >
        <h>{{ $t("gobackhome") }}</h>
      </button>
    </div>
  </div> -->
  <iframe
    :src="Global.debitcardInfo.response"
    style="width: 100% ; height: 75vh;"
    frameBorder="0"
  ></iframe>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "thankyou",
  mixins: [Mixin],
  data() {
    return {
      Global,
    };
  },
  created() {
    this.loading("hide");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    gobackhome() {
      location.reload();
    },
  },
};
</script>
<i18n>
{
  "en": {
    "thankyou": "Your Payment has been Processed ",
    "message": "Thank you for your trust in Roojai! ",
    "message2" : "If you chose e-policy, your policy details will be available on ",
    "message3" : " within 15 minutes after we’ve received your payment. ",
    "message4" : "If you have any questions or need further information, please contact us at ",
    "no_contact" : "021 5089 0822.",
    "gobackhome" : "Go to Home Page"
  },
  "id": {
    "thankyou": "Pembayaran Berhasil Diproses ",
    "message": "Terima kasih telah mempercayakan Roojai untuk memenuhi perlindunganmu ",
    "message2" : "Jika kamu memilih e-policy, maka detail polis akan muncul di ",
    "message3" : " dalam waktu 15 menit setelah pembayaranmu telah kami terima. ",
    "message4" : "Jika kamu memiliki pertanyaan atau butuh informasi lebih lanjut, silakan menghubungi kami di ",
    "no_contact" : "021 5089 0822.",
    "gobackhome" : "Kembali ke Halaman Utama"
  },
}
</i18n>